import PortalIdParser from 'PortalIdParser';
import Raven from 'raven-js';
import isObject from 'transmute/isObject';
import { makeIdentifierKey, parseIdentifierKey } from './identifierKey';

// Metadata for properties that started processing over 24 hours ago is stale and will be ignored
const STALENESS_THRESHOLD = 1000 * 60 * 60 * 24;
function makePersistenceKey() {
  return `smartProperties_${PortalIdParser.get()}`;
}

/**
 * PersistedMetadata is of the format:
 * @example
 * {
 *   "<identifierKey>": timestamp,
 *   "<identifierKey>": timestamp,
 *   ...
 * }
 */

function parseRecentTimestamp(rawTimestamp) {
  if (typeof rawTimestamp !== 'number' && typeof rawTimestamp !== 'string') {
    return null;
  }
  const parsedTimestamp = typeof rawTimestamp !== 'number' ? parseInt(`${rawTimestamp}`, 10) : rawTimestamp;
  if (isNaN(parsedTimestamp)) {
    Raven.captureException(new Error('Invalid timestamp in local storage'), {
      extra: {
        rawTimestamp
      }
    });
    return null;
  }
  if (Date.now() - parsedTimestamp >= STALENESS_THRESHOLD) {
    return null;
  }
  return parsedTimestamp;
}
function deserializeMetadata(rawPersistedMetadata) {
  if (rawPersistedMetadata === null || typeof rawPersistedMetadata !== 'string') {
    return {};
  }
  try {
    const persistedMetadata = JSON.parse(rawPersistedMetadata);
    if (persistedMetadata === null || !isObject(persistedMetadata)) {
      return {};
    }
    const parsedMetadata = {};
    for (const [identifierKey, timestamp] of Object.entries(persistedMetadata)) {
      const {
        objectTypeId,
        propertyName,
        objectId
      } = parseIdentifierKey(identifierKey);
      const startedAt = parseRecentTimestamp(timestamp);
      if (startedAt !== null) {
        parsedMetadata[makeIdentifierKey(objectTypeId, propertyName, objectId)] = {
          status: 'pending',
          isLoadingNewValue: false,
          startedAt
        };
      }
    }
    return parsedMetadata;
  } catch (error) {
    return {};
  }
}
function serializeMetadata(metadata) {
  const serializedMetadataByObjectTypeId = Object.entries(metadata).reduce((acc, [key, value]) => {
    if (value.status === 'pending') {
      acc[key] = value.startedAt;
    }
    return acc;
  }, {});
  return serializedMetadataByObjectTypeId;
}
export function loadProcessingMetadata(rawMetadata) {
  try {
    const metadata = rawMetadata !== undefined ? rawMetadata : localStorage.getItem(makePersistenceKey());
    return metadata ? deserializeMetadata(metadata) : {};
  } catch (e) {
    Raven.captureException(new Error('Error retrieving metadata'), {
      extra: {
        e
      }
    });
    return {};
  }
}
export function persistProcessingMetadata(metadata) {
  try {
    const serializedMetadata = serializeMetadata(metadata);
    const persistenceKey = makePersistenceKey();
    if (Object.keys(serializedMetadata).length > 0) {
      localStorage.setItem(persistenceKey, JSON.stringify(serializedMetadata));
    } else {
      localStorage.removeItem(persistenceKey);
    }
  } catch (e) {
    Raven.captureException(new Error('Error persisting metadata'), {
      extra: {
        e
      }
    });
  }
}
export const EXPORTED_FOR_TESTING = {
  STALENESS_THRESHOLD,
  parseRecentTimestamp,
  deserializeMetadata,
  serializeMetadata
};