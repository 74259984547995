import Raven from 'raven-js';
import { getSmartPropertyBatchRpc } from '../../__generated__/chirp/com/hubspot/custom/enrichment/rpc/client/SmartPropertyStatusService';
import { getOwnProperty } from '../utils/getOwnProperty';
import { makeIdentifierKey, parseIdentifierKey } from './identifierKey';
export const NO_STATUS = Symbol('NO_STATUS');
function mapStatus(status) {
  switch (status) {
    case 'RUNNING':
      return 'pending';
    case 'COMPLETE_SUCCESS':
      return 'completed';
    case 'COMPLETE_NO_RESULT':
      return 'completedNoResult';
    case 'FAILURE':
      return 'failed';
    default:
      return NO_STATUS;
  }
}
function collateRequestsByObjectTypeId(identifierKeys) {
  const requests = {};
  for (const key of identifierKeys) {
    var _getOwnProperty;
    const {
      objectTypeId,
      propertyName,
      objectId
    } = parseIdentifierKey(key);
    const request = (_getOwnProperty = getOwnProperty(requests, objectTypeId)) !== null && _getOwnProperty !== void 0 ? _getOwnProperty : {
      propertyNames: new Set(),
      objectIds: new Set()
    };
    request.propertyNames.add(propertyName);
    request.objectIds.add(parseInt(objectId, 10));
    requests[objectTypeId] = request;
  }
  return Object.entries(requests);
}
export async function pollSmartPropertyStatuses(rpcClient, identifierKeys) {
  const responses = await Promise.all(collateRequestsByObjectTypeId(identifierKeys).map(([objectTypeId, request]) => rpcClient.call(getSmartPropertyBatchRpc, {
    request: {
      objectTypeId,
      propertyNames: Array.from(request.propertyNames),
      objectIds: Array.from(request.objectIds)
    }
  }).then(result => {
    const statuses = {};
    for (const [objectId, statusesByPropertyName] of Object.entries(result.responses)) {
      for (const [propertyName, {
        status
      }] of Object.entries(statusesByPropertyName)) {
        const key = makeIdentifierKey(objectTypeId, propertyName, objectId);
        statuses[key] = mapStatus(status);
      }
    }
    return statuses;
  }).catch(e => {
    Raven.captureException(new Error('Error polling smart property statuses'), {
      extra: {
        e
      }
    });
    return {};
  })));
  const statuses = {};
  for (const response of responses) {
    for (const [key, value] of Object.entries(response)) {
      statuses[key] = value;
    }
  }

  // Every key that was requested and not returned by the backend should be returned with NO_STATUS
  for (const key of identifierKeys) {
    if (!Object.prototype.hasOwnProperty.call(statuses, key)) {
      statuses[key] = NO_STATUS;
    }
  }
  return statuses;
}