export function makeIdentifierKey(objectTypeId, propertyName, objectId) {
  return `${objectTypeId}/${propertyName}/${objectId}`;
}
export function parseIdentifierKey(key) {
  const [objectTypeId, propertyName, objectId] = key.split('/');
  return {
    objectTypeId,
    propertyName,
    objectId
  };
}